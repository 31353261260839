import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Alert } from 'react-bootstrap'

const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [prefferedTime, setPrefferedTime] = useState('')
  const [alertVariant, setAlertVariant] = useState(null)
  const [showAlert, setShowAlert] = useState(false)
  const [alertBody, setAlertBody] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [gdprConsent, setGdprConsent] = useState(false)

  const handleCheck = event => {
    setGdprConsent(event.target.value)
  }

  const handleSubmit = event => {
    event.preventDefault()
    postFormData()
  }

  const handleNameChange = event => {
    setName(event.target.value)
  }

  const handleEmailChange = event => {
    setEmail(event.target.value)
  }

  const handleTelChange = event => {
    setTel(event.target.value)
  }

  const handlePreferredTimeChange = event => {
    setPrefferedTime(event.target.value)
  }

  const postFormData = async () => {
    setIsLoading(true)

    const data = {
      name: name,
      email: email,
      tel: tel,
      preferredTime: prefferedTime,
      gdprConsent: gdprConsent
    }

    const url = 'https://willcentre-sendgrid-worker.activops.workers.dev'

    const reponse = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      redirect: 'follow',
      referrer: 'client',

      body: JSON.stringify(data)
    })

    if (reponse.ok) {
      setIsLoading(false)
      setAlertVariant('success')
      setAlertBody(
        "Thank you for your submission. We'll be in touch as soon as possible"
      )
      setShowAlert(true)
    }

    if (!reponse.ok) {
      setIsLoading(false)
      setAlertVariant('error')
      setAlertBody(
        'Thank you for your submission but there was an error.\nPlease call or email us directly.'
      )
      setShowAlert(true)
    }
  }

  return (
    <section className='bg-grey py-5 container-fluid' id='contact'>
      <div className='row justify-content-center'>
        <div className='col-md-4'>
          <div className='row'>
            <div className='col'>
              <h1 className='display-4'>Contact Us</h1>
              <h4 className='text-muted pe-5'>
                Get in touch with our expert team to give you some guidance on
                what we know best.
              </h4>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <form id='contact-form' onSubmit={handleSubmit}>
            <div className='mb-3'>
              <label htmlFor='name' className='form-label'>
                Name
              </label>
              <input
                type='text'
                className='form-control'
                id='name'
                aria-describedby='nameHelp'
                placeholder='Jane Doe'
                onChange={handleNameChange}
                value={name.value}
                required
              />
              <div id='nameHelp' className='form-text'>
                Full name preferrable
              </div>
            </div>

            <div className='mb-3'>
              <label htmlFor='email' className='form-label'>
                Email
              </label>
              <input
                type='email'
                className='form-control'
                id='email'
                aria-describedby='emailHelp'
                placeholder='jane@example.com'
                onChange={handleEmailChange}
                value={email}
                required
              />
              <div id='emailHelp' className='form-text'></div>
            </div>

            <div className='mb-3'>
              <label htmlFor='phone' className='form-label'>
                Phone
              </label>
              <input
                type='tel'
                className='form-control'
                id='phone'
                aria-describedby='phoneHelp'
                placeholder='07766554433'
                onChange={handleTelChange}
                value={tel}
                required
              />
              <div id='phoneHelp' className='form-text'></div>
            </div>

            <div className='mb-3'>
              <label htmlFor='preferredTimeSelect' className='form-label'>
                Preferred contact time
              </label>
              <select
                id='preferredTimeSelect'
                className='form-select'
                aria-describedby='preferredTimeSelectHelp'
                onChange={handlePreferredTimeChange}
                value={prefferedTime}
                required
              >
                <option defaultValue value=''>
                  Please select...
                </option>
                <option value='morning'>Morning</option>
                <option value='afternoon'>Afternoon</option>
                <option value='evening'>Evening</option>
              </select>
              <div id='preferredTimeSelectHelp' className='form-text'>
                What time of day would you like us to contact you?
              </div>
            </div>
            <div className='mb-3 form-check'>
              <label htmlFor='gdprConsentCheck' className='form-check-label'>
                I agree to my data being processed in accordance with the{' '}
                <Link to={'/privacy'}>Privacy Policy</Link>
              </label>
              <input
                className='form-check-input'
                id='gdprConsentCheck'
                type='checkbox'
                onChange={handleCheck}
                required
              />
              <div id='gdprConsentCheckHelp' className='form-text'></div>
            </div>
            <button
              className='btn-lg btn-secondary'
              type='submit'
              disabled={isLoading}
            >
              <span className='me-3'>
                {isLoading && (
                  <div
                    className='spinner-border spinner-border-sm'
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                )}
              </span>
              <span>
                {!isLoading && 'Submit'}
                {isLoading && 'Loading...'}
              </span>
            </button>
          </form>
          <Alert show={showAlert} variant={alertVariant} className={'pt-3'}>
            <Alert.Heading>Success</Alert.Heading>
            <p>{alertBody}</p>
          </Alert>{' '}
        </div>
      </div>
    </section>
  )
}

export default Contact
